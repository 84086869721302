import * as React from "react";
import { FluidObject } from "gatsby-image";
import Image from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";
import Gallery from "../Gallery";

export interface Props
{
    description: string;
    eventName: string;
    imageFolder: string;
}

const EventCard: React.FC<Props> = ({ description, eventName, imageFolder }) =>
{
    return (
        <StaticQuery query={query}>
            {(data) => {
                const images = data.events.nodes.filter((node: any) => node.relativeDirectory === `events/${imageFolder}`);
                return (
                    <div className="w-full h-full bg-white rounded-lg shadow-lg p-4">
                        <div className="w-full flex">
                            <Gallery images={images.map((image: any) => image.childImageSharp.fluid as FluidObject)} />
                        </div>
                        <h1 className="font-bold text-lg text-left mt-3">{eventName}</h1>
                        <div className="text-sm text-left mt-2">{description}</div>
                    </div>
                );
            }}
        </StaticQuery>
    );
};
export default EventCard;

const query = graphql`
    query {
        events: allFile(filter: {relativePath: { glob: "events/**/*"} }) {
            nodes {
                relativePath
                name
                relativeDirectory
                childImageSharp {
                    fluid (maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
`;
