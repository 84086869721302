import * as React from "react";
import Layout from "../../Layout";
import { graphql, StaticQuery } from "gatsby";
import Image from "gatsby-image";
import { StyledLink, StyledHeader } from "./styles";
import TeamCard from "../../TeamCard";
import LogoWoelffe from "./../../../images/logos/woelffe.svg";
import TeamData from "./team.json";
import { FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import EventCard from "../../EventCard";
import styled from "styled-components";
import vernissagePath from "./../../../data/Vernissage_Marlene_Lipski_8.3.20.pdf";

const events = [
    {
        date: "Sonntag, 15. und Sonntag 29. März",
        location: "Elverich 2a, 47495 Rheinberg (bei Büderich/Wesel)",
        title: "Musik am Hofcafé \"Hof Elverich\"",
        description: [
            "An den folgenden Sonntagen wird das Hofcafé Elverich in Büderich noch attraktiver, wenn die Schülerinnen und Schüler des KDG musizieren! Erlebnishof mit Spielplatz für die Kinder, Kaffee und Kuchen für die Erwachsenen und jede Menge Musik zum Spendensammeln - kommt zahlreich!"
        ],
    },
    {
        date: "Sonntag, den 8. März 2020 um 15 Uhr",
        location: "Johann-Sigismund-Str. 3, 46483 Wesel",
        title: "Gedächtnisausstellung im Atelier von Marlene Lipski",
        link: vernissagePath,
        description: [
            "Begrüßung: Dr. Veit Veltzke",
            "Musik: Mitglieder des DudenOrchestra, KDG",
        ],
    }
];

const Start = () =>
{
    const hash = typeof window !== "undefined" && window.location.hash && window.location.hash.slice(1) || null;
    React.useLayoutEffect(() =>
    {
        if (typeof document !== "undefined")
        {
            const headerHeight = document.getElementById("header").getBoundingClientRect().height;
            if (hash)
            {
                const scrollToElement = document.getElementById("section-" + hash);
                if (scrollToElement)
                {
                    document.getElementById("layout-container").scrollTop = scrollToElement.offsetTop - headerHeight;
                }
            }
        }
    }, [hash]);
    return (
        <Layout>
            <StaticQuery query={query}>
                {(data) => (
                    <>
                        <Image fluid={data.orchester.childImageSharp.fluid} className="w-full lg:h-full" />
                        <div className="absolute top-100 left-0 -mt-16 ml-4 w-48">
                            <a href="https://www.kdg-wesel.de" target="_blank"><Image fluid={data.logo_kdg.childImageSharp.fluid} className="w-full" /></a>
                        </div>
                        <section className="w-full p-5" id="section-wer-wir-sind">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Wer wir sind</h4>
                                <div className="w-full flex flex-wrap">
                                    <div className="md:text-lg text-md w-full">
                                        Ob Sängerin, Schlagzeuger, Cellist oder Geigerin: die Mitglieder des Schulorchesters „DudenOrchestra“ und der Schulband „Peavey“ am <StyledLink href="https://www.kdg-wesel.de/" target="_blank">Konrad-Duden-Gymnasium Wesel</StyledLink> haben 2020 erstmals die Möglichkeit, an einem kulturellen Austausch in Australien teilzunehmen.<br />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="w-full p-5">
                            <div className="container mx-auto">
                                <div className="w-full relative flex flex-wrap">
                                    {TeamData.map((teamMember: any) => {
                                        const image = data.team.edges.find((edge: any) => edge.node.name === teamMember.imageName);
                                        return (
                                            <TeamCard
                                                author={teamMember.name}
                                                age={teamMember.age}
                                                img={image ? image.node.childImageSharp.fluid : null}
                                                instrument={teamMember.instrument}
                                                key={teamMember.imageName}
                                                imgPosition={teamMember.imagePosition}
                                            >
                                                {teamMember.content}
                                            </TeamCard>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5" id="section-was-wir-planen">
                            <div className="container mx-auto flex flex-wrap">
                                <div className="md:text-lg text-md w-full">
                                    <h4 className="satisfy text-4xl">Was wir planen</h4>
                                    <p>
                                        Geplant ist ein 3-wöchiger Besuch, im Rahmen dessen wir zu Gast bei der <StyledLink href="https://www.igssyd.nsw.edu.au/" target="_blank">International Grammar School (IGS)</StyledLink> in Sydney sein werden. Die Schwerpunkte dieser Schule liegen auf Fremdsprachen, Musik sowie kreative und darstellende Künste. Zusammen mit den australischen Schülern sollen die jungen Musiker aus Wesel ein musikalisches Projekt gestalten, das sich u. a. ein gemeinsames und öffentliches Konzert zum Ziel gesetzt hat. Diese Zusammenarbeit schließt ein Kennenlernen des Schulalltags und des Familienlebens, sowie eine kulturelle Erkundung der Umgebung mit ein. Ein späterer Gegenbesuch der australischen Schüler in Wesel ist vorgesehen.<br /><br />
                                        Die CO2-Problematik eines Australienfluges ist uns sehr bewusst. Wir sind zu dem Entschluss gekommen, dass ein Verzicht auf Mobilität auch ein Verzicht auf globalen Austausch bedeutet, und zur Bewältigung globaler Probleme ist ein globaler Austausch und ein globales Verständnis füreinander unumgänglich. Gleichwohl gehört ein Beitrag unsererseits im Hinblick auf den Klimaschutz zu unseren Themen, z. B. im Rahmen der Initiative <StyledLink href="https://www.atmosfair.de/" target="_blank">www.atmosfair.de</StyledLink>. Interessierte Sponsoren, die das durch dieses Projekt verursachte CO2-Aufkommen kompensieren wollen, können sich gerne <StyledLink href="#kontakt">bei uns melden</StyledLink>. Was wir selber unternehmen, um dieser Problematik entgegenzukommen, werden Sie bald hier lesen können!
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Was wir bisher gemacht haben</h4>
                                <div className="text-md md:text-lg w-full">
                                    Von Anfang an war uns in der Planung dieses Austauschprogramms klar, dass die Finanzierung nicht ganz leicht sein würde. Daher haben wir vom ersten Moment an tatkräftig begonnen, Geld für das Projekt zu sammeln.
                                    <br /><br />
                                    <div className="w-full flex flex-wrap items-stretch">
                                        <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                            <EventCard
                                                description={`Im Rahmen des großen Aktionstages rund um das Thema Krebs boten wir eine musikalische Untermalung unter dem Motto "Music in the air".`}
                                                eventName="Niederrheinisches Zentrum für Tumorerkrankungen (NZT-Tag)"
                                                imageFolder="marienhospital"
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                            <EventCard
                                                description="Bei diesem Erlebnishof mit Spielplatz für die Kinder und Kaffee und Kuchen für die Erwachsenen gibt es von uns immer wieder jede Menge Musik zum Spendensammeln."
                                                eventName="Hof Elverich"
                                                imageFolder="hofelverich"
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                            <EventCard
                                                description="Bei bestem Wetter haben wir beim KleinMontMarte zusammen musiziert und Spenden gesammelt."
                                                eventName="Straßenmusik in Xanten"
                                                imageFolder="xanten"
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                            <EventCard
                                                description="Immer wieder stellen wir uns in die Weseler Innenstadt und musizieren zusammen, um Spenden für unsere Reise zu sammeln."
                                                eventName="Straßenmusik in Wesel"
                                                imageFolder="wesel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Unsere nächsten Auftritte</h4>
                                <div className="w-full mt-5 flex flex-wrap items-stretch">
                                    {events.map((event, index) => (
                                        <div key={index} className="p-2 w-full flex sm:w-1/2 md:w-1/3 lg:w-1/4">
                                            <div key={index} className="bg-white rounded-lg shadow w-full flex flex-col text-left pt-5">
                                                <StyledHeader className="font-bold px-5 break-words" title={event.title}>{event.title}</StyledHeader>
                                                <p className="mt-2 px-5 pb-5">
                                                    {event.description.map((description, index) => (
                                                        <StyledDescription key={index}>{description}</StyledDescription>
                                                    ))}
                                                    {event.link && (<a className="block mt-2 text-gray-800 text-xs" href={event.link} target="_blank">Weitere Informationen</a>)}
                                                </p>
                                                <div className="w-3/4 bg-red-200 self-center rounded-lg" />
                                                <div className="w-full px-5 py-3 flex-shrink-0">
                                                    <span className="text-xs block"><FaMapMarkerAlt className="inline mr-1" /> {event.location}</span>
                                                    <span className="text-xs block mt-2"><FaClock className="inline mr-1" /> {event.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5" id="section-was-wir-brauchen">
                            <div className="container mx-auto flex flex-wrap">
                                <div className="w-full md:w-1/2">
                                    <h4 className="text-4xl satisfy">Was wir brauchen</h4>
                                    <p className="text-md md:text-lg w-full">
                                        Der finanzielle Aufwand für das Projekt ist für viele Familien nur schwer tragbar, weshalb die Schüler und deren Eltern Veranstaltungen zur Finanzierung organisieren.
                                        <br />
                                        Wir freuen uns auch über direkte Spenden, die auf die nachfolgend genannten Konten überwiesen werden können. Eine Spendenquittung stellt der Förderverein des KDGs gerne aus. Bitte achten Sie dabei darauf, das Konto des Fördervereins zu verwenden.
                                    </p>
                                    <div className="w-full flex items-center justify-center mt-5">
                                        <a href="https://www.schulengel.de/einrichtungen/details/12476-dudenorchestra-des-konrad-duden-gymnasiums-wesel?utm_source=einrichtungsseite&utm_medium=spendenbanner&utm_campaign=spendenbanner122x240" target="_blank"><img src="https://www.schulengel.de/widgets/spendenbanner122x240-12476.png" /></a>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 md:pl-20 mt-5 md:mt-0">
                                    <h2 className="text-4xl satisfy">Spendenkonten</h2>
                                    <p className="text-md md:text-lg w-full">
                                        Vielen Dank, dass Sie sich dazu entschlossen haben, uns zu unterstützen. Wenn Sie eine Spendenquittung benötigen, nutzen Sie bitte folgendes Konto und geben Sie als Verwendungszweck <b>DudenOrchestra goes Australia</b> an.<br /><br />
                                        Förderverein des Konrad-Duden-Gymnasiums<br />
                                        Verbandssparkasse Wesel<br />
                                        IBAN DE51 3565 0000 0000 2151 78<br />
                                        BIC WELADED1WES<br /><br />
                                        Alternativ können Sie Ihre Spende auch auf unser Treuhandkonto überweisen. Eine Spendenquittung können wir dann allerdings leider nicht ausstellen.<br /><br />
                                        Kate Hildebrandt<br />
                                        Treuhandkonten<br />
                                        Niederrheinische Sparkasse RheinLippe<br />
                                        IBAN DE11 3565 0000 0001 0386 52<br />
                                        BIC WELADED1WES
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5" id="section-sponsoren">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Unsere Sponsoren</h4>
                                <div className="w-full py-5 flex items-center justify-center flex-wrap">
                                    <OutboundLink href="https://www.woelffe-design.de" target="_blank"><LogoWoelffe className="w-32 mx-5" /></OutboundLink>
                                    <OutboundLink href="https://www.komp.de" target="_blank"><Image className="w-32 mx-5" fluid={data.logo_komp.childImageSharp.fluid} /></OutboundLink>
                                    <OutboundLink href="https://kreutzlaw.de" target="_blank"><Image className="w-32 mx-5" fluid={data.logo_kreutz_partner.childImageSharp.fluid} /></OutboundLink>
                                    <OutboundLink href="http://www.konferenzdolmetscher-nrw.de/" target="_blank">
                                        <Image className="w-48 mx-5" fluid={data.logo_konferenzdolmetscher.childImageSharp.fluid} />
                                    </OutboundLink>
                                    <OutboundLink href="https://van-den-bruck.de/" target="_blank"><Image className="w-64 mx-5" fluid={data.logo_van_den_bruck.childImageSharp.fluid} /></OutboundLink>
                                    <Image className="w-16 mx-5" fluid={data.logo_dr_wickum.childImageSharp.fluid} />
                                    <OutboundLink href="https://www.lions.de/web/lc-hamminkeln" target="_blank">
                                        <Image className="w-16 mx-5" fluid={data.logo_lions_club.childImageSharp.fluid} />
                                    </OutboundLink>
                                </div>
                            </div>
                        </section>
                        <section className="p-2 bg-red-800" />
                        <section className="w-full p-5" id="section-kontakt">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Kontakt</h4>
                                <p className="text-md md:text-lg w-full">
                                    Sie haben Fragen bezüglich unseres Projektes oder möchten mit uns in Kontakt treten? <br />
                                    Schreiben Sie uns gerne eine E-Mail an: <a className="underline font-bold" href="mailto:k.hildebrandt@kdg.wesel.de">k.hildebrandt@kdg.wesel.de</a>
                                </p>
                            </div>
                        </section>
                        <section className="w-full p-5 bg-red-800 text-white" id="section-impressum">
                            <div className="container mx-auto">
                                <h4 className="satisfy text-4xl">Impressum</h4>
                                <p className="text-md md:text-lg w-full">
                                    <strong className="my-2 block">Angaben gemäß § 5 TMG</strong>
                                    Kate Hildebrandt<br />
                                    Konrad-Duden-Gymnasium Wesel<br />
                                    Barthel-Bruyn-Weg 54<br />
                                    46483 Wesel<br />
                                    <strong className="my-2 block">Kontakt</strong>
                                    E-Mail: <a className="underline font-bold" href="mailto:k.hildebrandt@kdg.wesel.de">k.hildebrandt@kdg.wesel.de</a>
                                    <strong className="my-2 block">Haftung für Inhalte</strong>
Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
<strong className="my-2 block">Haftung für Links</strong>
Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
<strong className="my-2 block">Urheberrecht</strong>
Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
<strong className="my-2 block">Quelle</strong>
<a className="font-bold text-xs underline" href="https://www.e-recht24.de/impressum-generator.html" target="_blank">https://www.e-recht24.de/impressum-generator.html</a>
                                </p>
                            </div>
                        </section>
                    </>
                )}
            </StaticQuery>
        </Layout>
    )
};
export default Start;

const StyledDescription = styled.span`
    display: block;
`;

const query = graphql`
    query {
        orchester: file(relativePath: { eq: "Orchester.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_kdg: file(relativePath: { eq: "logos/KDG_LOGO.png" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_komp: file(relativePath: { eq: "logos/komp.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_lions_club: file(relativePath: { eq: "logos/lions_club.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_kreutz_partner: file(relativePath: { eq: "logos/kreutz_partner.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_van_den_bruck: file(relativePath: { eq: "logos/van_den_bruck.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_dr_wickum: file(relativePath: { eq: "logos/dr_wickum.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo_konferenzdolmetscher: file(relativePath: { eq: "logos/konferenzdolmetscher.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        team: allFile(filter: {
            relativeDirectory: {
                eq: "team"
            }
        }) {
            edges {
                node {
                    id
                    name
                    relativeDirectory
                    extension
                    childImageSharp {
                        fluid (maxWidth: 500) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`;
