import styled from "styled-components";

export const StyledTeamCard = styled.div`
    & {
        .show-on-hover {
            top: 100%;
            transition: all 150ms ease-in-out;
        }

        &:hover
        {
            .show-on-hover
            {
                top: 0%;
            }
        }
    }
`;