import * as React from "react";
import * as ReactDOM from "react-dom";
import Image, { FluidObject } from "gatsby-image";
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa";

export interface Props
{
    image: FluidObject;
    onIndexChange: (index: number | null) => void;
    currentIndex: number;
    hasNext: boolean;
    hasPrev: boolean;
}
const Overlay: React.FC<Props> = ({ image, hasPrev, hasNext, currentIndex, onIndexChange }) =>
{
    const nodeTarget = document.getElementById("overlay-target");
    const onNavClick = (direction: "prev" | "next") =>
    {
        onIndexChange(direction === "prev" ? currentIndex - 1 : currentIndex + 1);
    };
    return ReactDOM.createPortal((
        <div className="w-full h-full relative flex items-center justify-center" style={{ pointerEvents: "all" }}>
            <div className="w-full h-full absolute top-0 left-0 bg-gray-900 opacity-50" onClick={() => onIndexChange(null)} />
            <div className="w-10/12 h-11/12 relative rounded-lg overflow-hidden">
                <Image fluid={image} className="w-full h-full max-h-full select-none" />
                {hasPrev && (
                    <div
                        className="h-full p-2 flex items-center justify-center absolute top-0 left-0 text-white text-3xl cursor-pointer"
                        onClick={() => onNavClick("prev")}
                    >
                        <div className="w-full h-full absolute top-0 left-0 bg-white opacity-0 hover:opacity-25" />
                        <FaChevronCircleLeft className="shadow rounded-full" />
                    </div>
                )}
                {hasNext && (
                    <div
                        className="h-full p-2 flex items-center justify-center absolute top-0 right-0 text-white text-3xl cursor-pointer"
                        onClick={() => onNavClick("next")}
                    >
                        <div className="w-full h-full absolute top-0 left-0 bg-white opacity-0 hover:opacity-25" />
                        <FaChevronCircleRight className="shadow rounded-full" />
                    </div>
                )}
            </div>
        </div>
    ), nodeTarget);
};
export default Overlay;
