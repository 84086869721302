import * as React from "react";
import Overlay from "./components/Overlay";
import { FluidObject } from "gatsby-image";
import Image from "./components/Image";

export interface Props
{
    images: FluidObject[];
}

const Gallery: React.FC<Props> = ({ images }) =>
{
    const [selectedImage, setSelectedImage] = React.useState<number | null>(null);
    return (
        <div className="w-full flex h-64 md:h-48 lg:h-64 rounded-lg overflow-hidden">
            {imageTemplates(images, setSelectedImage)}
            {selectedImage !== null && (
                <Overlay
                    currentIndex={selectedImage || 0}
                    image={images[selectedImage]}
                    onIndexChange={setSelectedImage}
                    hasNext={images.length - 1 >  selectedImage}
                    hasPrev={selectedImage > 0}
                />
            )}
        </div>
    );
};
export default Gallery;

const imageTemplates = (images: FluidObject[], setSelectedImage: (index: number | null) => void) =>
{
    if (images.length > 6)
    {
        return (
            <>
                <div className="w-1/3 h-full flex flex-col border-r border-white">
                    <div className="w-full flex-1 border-b border-white relative">
                        <Image image={images[0]} onClick={() => setSelectedImage(0)} />
                    </div>
                    <div className="w-full flex-1">
                        <Image image={images[3]} onClick={() => setSelectedImage(3)} />
                    </div>
                </div>
                <div className="w-1/3 h-full flex flex-col border-r border-white">
                    <div className="w-full flex-1 border-b border-white">
                        <Image image={images[1]} onClick={() => setSelectedImage(1)} />
                    </div>
                    <div className="w-full flex-1">
                        <Image image={images[4]} onClick={() => setSelectedImage(4)} />
                    </div>
                </div>
                <div className="w-1/3 h-full flex flex-col">
                    <div className="w-full flex-1 border-b border-white">
                        <Image image={images[2]} onClick={() => setSelectedImage(2)} />
                    </div>
                    <div
                        className="w-full flex-1 flex items-center justify-center hover:bg-gray-300 cursor-pointer border-r border-b border-gray-300 rounded-br-lg"
                        onClick={() => setSelectedImage(5)}
                    >
                        + {images.length - 5}
                    </div>
                </div>
            </>
        );
    }
}
