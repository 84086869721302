import * as React from "react";
import { StyledLink, StyledLayout, GlobalStyle } from "./styles";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

const Layout: React.FC = ({ children }) =>
{
    const [isScrolled, setIsScrolled] = React.useState(false);
    const threshold = 50;
    const onScroll = (event: React.UIEvent<HTMLDivElement>) =>
    {
        const target = event.target as HTMLDivElement;
        if (target.scrollTop > threshold && !isScrolled) setIsScrolled(true);
        if (target.scrollTop <= threshold && isScrolled) setIsScrolled(false);
    };
    return (
        <StaticQuery query={query}>
            {data => (
                <>
                    <GlobalStyle />
                    <StyledLayout className="overflow-x-hidden overflow-y-auto bg-red-100 text-center relative" onScroll={onScroll} id="layout-container">
                        <div className="w-full sticky top-0 z-10 left-0 bg-white shadow">
                            <div className={`container mx-auto text-white flex justify-center md:justify-start items-center relative h-24`} id="header">
                                <div className="flex flex-1 justify-end">
                                    <StyledLink to="/#wer-wir-sind">Wer wir sind</StyledLink>
                                    <StyledLink to="/#was-wir-planen">Was wir planen</StyledLink>
                                    <StyledLink to="/#was-wir-brauchen">Was wir brauchen</StyledLink>
                                </div>
                                <div className={`w-40 md:w-48 flex flex-grow-0 items-center justify-center ${!isScrolled && "self-start"}`}>
                                    <Image className={`${isScrolled ? "w-32" : "w-40 md:w-48 mt-5 self-start bg-white"}`} fluid={data.file.childImageSharp.fluid} />
                                </div>
                                <div className="flex flex-1 justify-start">
                                    <StyledLink to="/#sponsoren">Unsere Sponsoren</StyledLink>
                                    <StyledLink to="/#kontakt">Kontakt</StyledLink>
                                    <StyledLink to="/#impressum">Impressum</StyledLink>
                                </div>
                            </div>
                        </div>
                        <div className="w-full relative">
                            {children}
                        </div>
                        <div id="overlay-target" className="w-full h-full fixed top-0 left-0 z-40" style={{ pointerEvents: "none" }} />
                    </StyledLayout>
                </>
            )}
        </StaticQuery>
    );
};
export default Layout;

const query = graphql`
    query {
        file(relativePath: { eq: "Logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;
