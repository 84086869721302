import * as React from "react";
import { FluidObject } from "gatsby-image";
import Image from "gatsby-image";
import { StyledTeamCard } from "./styles";

export interface Props
{
    author: string;
    instrument: string;
    age: number;
    img?: FluidObject;
    imgPosition: "top" | "center";
}
const TeamCard: React.FC<Props> = ({ author, age, children, instrument, img, imgPosition }) =>
{
    return (
        <div className="w-1/2 sm:w-1/3 lg:w-1/4 pr-5 pb-5">
            <StyledTeamCard className="w-full flex items-center justify-center flex-col rounded-lg shadow-lg overflow-hidden relative">
                <div className="w-full h-64 flex-shrink-0">
                    {img && (<Image fluid={img} className="w-full h-full" imgStyle={{ objectPosition: `${imgPosition} center` }} />)}
                </div>
                <div className="text-center pt-3 pb-2 satisfy text-lg w-full bg-white">
                    {author} - {instrument} ({age})
                </div>
                <div className="absolute t-0 l-0 w-full h-full bg-red-800 show-on-hover text-white p-5 overflow-x-auto">
                    <div className="w-full">
                        {children}
                    </div>
                </div>
            </StyledTeamCard>
        </div>
    )
};
export default TeamCard;
