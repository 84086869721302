import * as React from "react";
import GatsbyImage, { FluidObject } from "gatsby-image";

export interface Props
{
    image: FluidObject;
    onClick: () => void;
}
const Image: React.FC<Props> = ({ image, onClick }) =>
{
    return (
        <div className="w-full h-full relative cursor-pointer" onClick={onClick}>
            <GatsbyImage fluid={image} className="w-full h-full" />
            <div className="w-full h-full absolute top-0 left-0 bg-white z-10 opacity-0 hover:opacity-25" />
        </div>
    );
};
export default Image;
