import styled from "styled-components";

export const FeaturedWrapper = styled.div`
    width: 100%;
`;

export const Featured = styled.div`
    min-height: 450px;
    max-height: 600px;
    min-width: 600px;
    max-width: 1000px;
`;

export const Hero = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledLink = styled.a.attrs({
    className: "text-red-900 font-bold underline"
})``;

export const StyledDivider = styled.div.attrs({
    className: "w-full mt-5 mb-10"
})`
    position: relative;
	margin-top: 90px;
	height: 1px;

    &:before
    {
        content: "";
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        width: 90%;
        height: 1px;
        background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
    }

    &:after
    {
        content: "";
        position: absolute;
        z-index: 1;
        top: -10px;
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-color: rgb(116,42,42);
        border: 1px solid rgb(48,49,51);
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px white,
                        0 0 0 4px white;
    }
`;

export const StyledHeader = styled.h4`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3em;
    overflow: hidden;
`;

export const StyledLocation = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3em;
    overflow: hidden;
`;
